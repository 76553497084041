import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import Composition from '../../components/methode-raypath/Composition'

const CompositionPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathComposition">
    <Composition />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('composition')
)

export default echance(CompositionPage)
