import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  Section, Grid, ImageBasic, Paper
} from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Helmet from 'react-helmet'
import { withNamespaces } from 'react-i18next'

import Classes from '../../styles/classes'
import Config from '../../Config'

const styles = StyleSheet.create({
  image: {
    borderRadius: '0.5em'
  },
  bold: {
    fontWeight: 'bold'
  },
  imageMaxHeight: {
    maxHeight: '300px',

    width: 'auto',
    height: 'auto'
  },
  tableCellHead: {
    backgroundColor: Config.colors.primary,
    color: '#fff',
    fontWeight: 'bold'
  },
  tableCell: {
    border: 'solid 1px #ccc',
    borderCollapse: 'collapse'
  }
})
const Composition = ({ t }) => (
  <React.Fragment>
    <Helmet
      title={t('head.title')}
      meta={[
        {
          name: 'description',
          content: t('head.meta-description')
        }
      ]}
    />
    <Section className={css(Classes.section)}>
      <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.3')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image031.png"
                className={css(Classes.image, styles.imageMaxHeight)}
                size="small"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image034.png"
                className={css(Classes.image, styles.imageMaxHeight)}
                size="small"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image035.png"
                className={css(Classes.image, styles.imageMaxHeight)}
                size="medium"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <p
            className={css(Classes.headline, Classes.bold)}
            dangerouslySetInnerHTML={{
              __html: t('sections.0.accroche')
            }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={css(styles.tableCell, styles.tableCellHead)}>
                  {t('sections.0.tableau.titres.0')}
                </TableCell>
                <TableCell className={css(styles.tableCell, styles.tableCellHead)}>
                  {t('sections.0.tableau.titres.1')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.0.case1')}
                </TableCell>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.0.case2')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.1.case1')}
                </TableCell>
                <TableCell className={css(styles.tableCell)}>
                  <ul>
                    <li>{t('sections.0.tableau.lignes.1.case2.0')}</li>
                    <li>{t('sections.0.tableau.lignes.1.case2.1')}</li>
                    <li>{t('sections.0.tableau.lignes.1.case2.2')}</li>
                    <li>{t('sections.0.tableau.lignes.1.case2.3')}</li>
                  </ul>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.2.case1')}
                </TableCell>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.2.case2')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={css(styles.tableCell)}>
                  <p>{t('sections.0.tableau.lignes.3.case1.0')}</p>
                  <p>
                    <small>{t('sections.0.tableau.lignes.3.case1.1')}</small>
                  </p>
                </TableCell>
                <TableCell className={css(styles.tableCell)}>
                  {t('sections.0.tableau.lignes.3.case2.0')}
                  <ul>
                    <li>{t('sections.0.tableau.lignes.3.case2.1')}</li>
                    <li>{t('sections.0.tableau.lignes.3.case2.2')}</li>
                    <li>{t('sections.0.tableau.lignes.3.case2.3')}</li>
                  </ul>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Section>
    <Section className={css(Classes.section)}>
      <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.1')
              }}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.2')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image004.png"
                className={css(Classes.image)}
                size="small"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image005.jpg"
                className={css(Classes.image)}
                size="medium"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.4')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.5')
              }}
            />
            <p
              className={css(Classes.paragraph, styles.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.6')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={6}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image006.png"
                className={css(Classes.image)}
                size="medium"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.7')
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  </React.Fragment>
)

export default withNamespaces('composition')(Composition)
